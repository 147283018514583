var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "best-apartments-block" },
    [
      _c(
        "content-wrapper-block",
        [
          _c("page-title", [_vm._v("Лучшие предложения")]),
          _c(
            "div",
            { staticClass: "best-apartments-block__slider" },
            [
              _c(
                "swiper",
                {
                  ref: "swiper",
                  staticClass: "best-apartments-block__swiper",
                  attrs: {
                    options: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                      pagination: {
                        el: ".swiper-best-pagination",
                        clickable: true,
                      },
                      breakpoints: {
                        1100: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                      },
                    },
                  },
                },
                [
                  _vm._l(_vm.bestApartments, function (apartment, index) {
                    return _c(
                      "swiper-slide",
                      {
                        key: index,
                        staticClass: "best-apartments-block__slide",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "ApartmentPage",
                                params: {
                                  korp: apartment.korpus,
                                  sec: apartment.section,
                                  floor: apartment.floor,
                                  fnumb: apartment.number_on_floor,
                                  quantity: apartment.quantity,
                                  id: apartment.id,
                                },
                              },
                            },
                          },
                          [
                            _c("apartment-card", {
                              attrs: {
                                "plan-image": _vm.getPlanImage(apartment, true),
                                area: apartment.area,
                                rooms: apartment.quantity,
                                floor: apartment.floor,
                                number: apartment.number,
                                korpus: apartment.korpus,
                                cost: apartment.discount_cost,
                                price: _vm.$ipoteka(
                                  apartment.discount_cost,
                                  apartment.discount_cost * 0.5,
                                  _vm.minPercent,
                                  30,
                                  _vm.storage.mortgage.apply_coefficient
                                    ? _vm.storage.mortgage.coefficient
                                    : 1
                                ),
                                alternative: apartment.alternative,
                                properties: apartment.properties,
                                "hide-tag-text": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c("swiper-red-pagination", {
                    staticClass: "swiper-best-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination",
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "best-apartments-block__footer" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "FilterPage" } } },
                [
                  _c("ui-button", { attrs: { outline: "" } }, [
                    _vm._v(" все планировки "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }