<template>
  <div class="best-apartments-block">
    <content-wrapper-block>
      <page-title>Лучшие предложения</page-title>
      <div class="best-apartments-block__slider">
        <swiper
          ref="swiper"
          class="best-apartments-block__swiper"
          :options="{
            slidesPerView: 1,
            spaceBetween: 20,
            pagination: {
              el: '.swiper-best-pagination',
              clickable: true,
            },
            breakpoints: {
              1100: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
            },
          }"
        >
          <swiper-slide
            v-for="(apartment, index) in bestApartments"
            :key="index"
            class="best-apartments-block__slide"
          >
            <router-link
              :to="{
                name: 'ApartmentPage',
                params: {
                  korp: apartment.korpus,
                  sec: apartment.section,
                  floor: apartment.floor,
                  fnumb: apartment.number_on_floor,
                  quantity: apartment.quantity,
                  id: apartment.id,
                },
              }"
            >
              <apartment-card
                :plan-image="getPlanImage(apartment, true)"
                :area="apartment.area"
                :rooms="apartment.quantity"
                :floor="apartment.floor"
                :number="apartment.number"
                :korpus="apartment.korpus"
                :cost="apartment.discount_cost"
                :price="
                  $ipoteka(
                    apartment.discount_cost,
                    apartment.discount_cost * 0.5,
                    minPercent,
                    30,
                    storage.mortgage.apply_coefficient ? storage.mortgage.coefficient : 1
                  )
                "
                :alternative="apartment.alternative"
                :properties="apartment.properties"
                hide-tag-text
              />
            </router-link>
          </swiper-slide>
          <swiper-red-pagination
            slot="pagination"
            class="swiper-best-pagination"
          />
        </swiper>
      </div>
      <div class="best-apartments-block__footer">
        <router-link :to="{ name: 'FilterPage' }">
          <ui-button outline> все планировки </ui-button>
        </router-link>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import ApartmentCard from "@/components/elements/ApartmentCard";
import SwiperRedPagination from "@/components/elements/SwiperRedPagination";
import UiButton from "@/components/ui/UIButton";
import { mapState } from "vuex";

export default {
  name: "BestApartmentsBlock",
  components: {
    UiButton,
    SwiperRedPagination,
    ApartmentCard,
    PageTitle,
    ContentWrapperBlock,
  },
  computed: {
    ...mapState({
      apartments: (state) => state.apartments,
      alternativePlans: (state) => state.alternativePlans,
      minPercent: (state) => state.minPercent,
      storage: (state) => state.storage
    }),
    bestApartments() {
      this.apartments.filter((e) => {
        for (const key in this.alternativePlans) {
          const numPlans = this.alternativePlans[key].name;
          for (let i = 0; i < numPlans.length; i++) {
            if (e.number === numPlans[i]) {
              e.alternative = true;
            }
          }
        }
      });
      return this.apartments.slice(0, 6);
    },
  },
};
</script>
<style scoped lang="scss">
.best-apartments-block {
  background-color: #f4f4f4;
  padding-bottom: 30px;

  &__slide {
    box-sizing: border-box;

    a {
      text-decoration: none;
    }
  }

  &__swiper {
    overflow: visible;
  }

  &__footer {
    margin-top: 15px;
    text-align: center;

    @media screen and (min-width: $screen-sm) {
      text-align: left;
    }
  }

  ::v-deep {
    .apart-container__cost-now_min {
      font-size: 12px !important;
      @media screen and (min-width: $screen-xs) {
        font-size: 16px !important;
      }

      @media screen and (min-width: $screen-sm) {
        font-size: 14px !important;
      }

      @media screen and (min-width: $screen-md) {
        font-size: 13px !important;
      }
    }

    .apart-container__cost-now {
      font-size: 11px;

      @media screen and (min-width: $screen-sm) {
        font-size: 12px !important;
      }
    }
  }
}

.swiper-best-pagination {
  margin-top: 30px;
}
</style>
